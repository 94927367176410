.login-page {
  width: 100%;
  height: calc(100vh - 40px);
  transition: 0.2s ease-in;
  position: relative;

  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    transition: 0.2s ease-in;
    .logo {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      transition: 0.2s ease-in;
      img {
        pointer-events: none;
        max-width: 100px;
      }
      h1 {
        font-size: 18px;
        padding-left: 28px;
        user-select: none;
        color: var(--primary-color);
      }
    }
    .intro {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      user-select: none;
      margin: -40px 0 40px 0;
    }
    .cards {
      width: 100%;
      display: grid;
      grid-gap: 2em;
      grid-template-columns: 1fr;
      max-width: 250px;
      .card {
        position: relative;
        min-height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--tertiary-color);
        color: var(--white);
        font-size: 16px;
        user-select: none;
        span {
          transition: 0.2s ease-in;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1.5px;
            width: 0%;
            background: var(--white);
            transition: 0.2s ease-in;
          }
        }
        &:hover:not(.card--active) {
          cursor: pointer;
          span {
            transform: scale(1.1);
            &:after {
              width: 100%;
            }
          }
        }
        &--active {
          border: 2px solid var(--secondary-color);
          background: linear-gradient(
                          -45deg,
                          #9ba9b6,
                          #008080,
                          #00a89f,
                          #006666
          );
          background-size: 400% 400%;
          animation: gradient-animation 10s ease infinite;
          transition: 0.2s ease-in;
          &:before{
            content:'✔';
            margin-right: 8px;
            transition: 0.2s ease-in;
          }
        }
      }
    }

    .login-instance {
      height: 40px;
      width: 140px;
      margin-top: 50px;
    }

    .login-loading-wrap {
      .ant-row {
        height: 100% !important;
        h3 {
          margin-top: 50px;
        }
      }
    }
  }
  .info {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: help;
    svg {
      font-size: 15px;
      color: var(--dark-green);
    }
  }
}
