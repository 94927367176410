.progressBoxes {
  width: 100%;
  padding-left: 1%;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 6em;
  span{
    font-size: 1.25em;
    line-height: 2em;
  }
  .errored {
    color: var(--red)
  }
  .success {
    color: var( --primary-color )
  }
  .pending {
    color: yellowgreen;
  }
  .box {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    .ant-progress-text {
      cursor: help;
      span{
        font-size:12px;
        user-select: none;
      }
    }
  }
}
