.comment-reply-buttons {
  text-align: right;
  button {
    margin-right: 24px;
    &:last-of-type{
      margin-right: 0;
    }
  }
}
.comment-commenter-container {
  width: 100%;
  min-width: 200px;
}
