#projectIdFilter {
  display: block;
  width: 100%;
  padding: 0 20px 0 0;
  .label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  .projectIdsFiltered {

    border: 1px solid rgb(225, 231, 235);
    margin-top: 20px;
    border-radius: 4px;
    height: 100%;
  }
  .clear-projectId-btn {
    border: none;
    background: transparent;
    position: absolute;
    top: -3px;
    right: 20px;
  }
  .ant-tag {
    margin-top: 2px;
  }
}
