/* Css Variables  */
:root {
  /* colors  */
  --primary-color: #008080;
  --secondary-color: #808184;
  --tertiary-color: #00a89f;

  --black: #282c36;
  --white: #fafafa;
  --dark-green: #006666;
  --grey: #9ba9b6;
  --red: #eb2c51;

  /* sizes */
  --content-size: 2000px;
  --header-bar-size: 64px;
  --small-header-bar-size: 40px;
}

@media screen and (max-width: 2500px) and (min-width: 1900px) {
  :root {
    --content-size: 2200px;
  }
}
@media screen and (max-width: 3000px) and (min-width: 2500px) {
  :root {
    --content-size: 2600px;
  }
}

@media screen and (min-width: 3000px) {
  :root {
    --content-size: 3000px;
  }
}
