.upload-modal-table {
  margin-top: 20px;
}
.upload-modal-table .ant-table-container table th,
td {
  text-align: left;
}
.upload-modal-table .ant-table-container table thead {
  border-bottom: 1px dashed var(--primary-color);
}
.upload-modal-table .ant-table table {
  border: 1px dashed var(--primary-color);
  border-collapse: none !important;
}
.upload-modal-table .ant-table-container table th {
  font-weight: bold;
  border-bottom: 1px dashed var(--primary-color);
}

.upload-modal-table .anticon-delete {
  transition: 0.2s ease-in;
}

.upload-modal-table .anticon-delete:hover {
  color: var(--red);
}

.upload-modal-table .ant-table-placeholder .ant-table-cell {
  border-bottom: none;
  padding: 0 !important;
  text-align: center;
}
.upload-modal-table .ant-table-empty .ant-table-placeholder .ant-table-cell {
  padding: 10px 0 !important;
  user-select: none;
}

.match-menu .ant-dropdown-menu {
  max-height: 350px;
  overflow: auto;
}

.match-menu .ant-dropdown-menu .menu-item-button {
  background: transparent;
  border: unset;
  cursor: pointer;
}

.match-menu .ant-dropdown-menu .menu-item-button.isActive {
  color: var(--primary-color);
}

/* fix matched files that are disabled  */
.upload-modal-table .ant-typography.ant-typography-success {
  color: var(--primary-color) !important;
}
