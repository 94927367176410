.reports {
  position: relative;
  margin-left: -20px;
  .reports-sidebar {
    background: var(--white);
    padding-top: 60px;
    min-height: calc(100vh - 120px);
    .ant-menu {
      .ant-menu-item {
        &:hover {
          background-color: var(--tertiary-color);
          .ant-menu-title-content {
            color: var(--white);
          }
        }
        .ant-menu-title-content {
          padding-left: 8px;
        }
      }
      .ant-menu-item-selected {
        background: var(--primary-color);
        color: var(--white);
      }
    }
  }
  main {
    padding: 10px;
  }
  // Report details page
  .report-details {
    margin-bottom: 30px;
    .ant-descriptions-row {
      .ant-descriptions-item-label {
        width: 150px;
      }
    }
  }
  .my-reports-page {
    .ant-table-cell,
    .reports-page .ant-table-cell {
      padding: 8px 10px;
      p {
        margin-bottom: 0;
      }
    }
    .my-reports-table {
      .ant-table {
        min-height: 520px;
        .delete-report-container {
          display: flex;
          justify-content: center;
          .anticon {
            color: var(--red);
            transition: 0.2s ease-in;
          }
          &:hover {
            cursor: pointer;
            .anticon {
              color: var(--primary-color);
            }
          }
          &.ant-popover-open {
            .anticon {
              color: var(--primary-color);
            }
          }
        }
      }
    }
  }
}

.reports-modal {
  .ant-modal-content {
    max-height: 550px;
    overflow-y: scroll;
    overflow-x: auto;
    .ant-modal-body {
      padding-right: 0px;
      .ant-form-item {
        margin-bottom: 14px;
      }
    }
  }
  .ant-form-vertical .ant-form-item-label {
    padding: 0 0 2px;
  }
  .ant-select-multiple .ant-select-selection-item {
    height: 100%;
    flex-wrap: wrap;
    caret-color: var(--tertiary-color);
    .ant-tag {
      margin: 3px;
    }
  }
  .ant-select-multiple {
    .ant-select-selector::after {
      content: unset !important;
    }
  }

  .select-multiple .ant-select-selector {
    padding: 5px;
  }

  .ant-select-selection-search-input::placeholder {
    color: #bfbfbf;
  }

  // fix for smaller screens
  @media screen and (max-height: 700px) {
    top: calc(var(--header-bar-size) + 30px);
  }
  &-placeholder {
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 450px;
      .ant-spin {
        margin-bottom: 20px;
        .anticon {
          font-size: 32px;
        }
      }
    }
  }
}
