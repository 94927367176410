.add-project-rate-drawer {
  .ant-drawer-header {
    display: flex;
    align-items: center;
    background-color: var(--primary-color);
    border-radius: 0px;
    padding: 10px 18px;
    .ant-drawer-title {
      color: var(--white);
      user-select: none;
    }
    .ant-drawer-close {
      font-size: 16px;
      top: 3px;
      color: var(--white);
      padding: 10px;
      &:hover {
        color: #414141;
        transform: scale(1.1);
      }
    }
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-form-horizontal {
    .ant-form-item-control {
      align-items: flex-end;
      .ant-form-item-control-input {
        width: 350px;
      }
    }
  }

  .ant-select {
    .anticon-spin {
      svg {
        fill: var(--primary-color);
      }
    }
  }
}
