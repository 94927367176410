.reports-project-ids-filter {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--tertiary-color);
  padding: 2px;
  .ant-select-selector {
    border-radius: 4px;
    border: unset!important;
  }
  input {
    border-radius: 4px;
    width: 100%;
    padding: 4px 8px;
    border: unset;
    &:focus {
      border: unset;
      outline: none;
    }
  }
  .ant-tag {
    border: 1px solid var(--tertiary-color);
  }
  .ant-select-selection-search-mirror {
    display: none;
  }
}
