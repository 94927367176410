/**** adjustments  ****/

/* table header  */

.adjustments .ant-collapse {
    margin-bottom: 20px;
  }
  
  .adjustments .ant-collapse >.ant-collapse-item >.ant-collapse-header {
    padding: 7px 35px;
    color: var(--primary-color);
  }
  
  .adjustments .ant-collapse > .ant-collapse-item:last-child,
  .adjustments .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 4px !important;
  }
  
  .adjustments .ant-collapse-content {
    border-top: 1px solid var(--grey);
  }
  
  .adjustments .ant-form-item-label > label {
    color: rgba(0, 0, 0, 0.65);
  }
  
  .adjustments .ant-form-vertical .ant-form-item-label {
    padding-bottom: 0;
  }
  
  .adjustments div:nth-child(2) .ant-row {
    padding: 0 !important;
  }
  
  .adjustments .ant-table-thead > tr > th {
    color: var(--primary-color);
    font-weight: bold;
    border-bottom: 1px solid var(--grey);
    padding: 10px 8px !important;
  }
  .adjustments .ant-table-thead > tr > th.ant-table-selection-column{
    border-right: 1px solid var(--grey);
  }
  .adjustments .ant-table-thead > tr > .ant-table-column-sort {
    background: #e1e7eb !important;
  }
  /* pagination  */
  .adjustments .ant-pagination {
    margin: 30px 0;
  }
  
  /* table body  */
  .adjustments .ant-table table{
    border: 1px solid  var(--grey);
    border-radius: 4px;
  }
  
  .adjustments .ant-table-tbody > tr.ant-table-row:hover > td {
    background: unset;
  }
  
  .adjustments .ant-table-tbody .ant-table-row-selected:hover {
    background: unset;
  }
  
  .adjustments .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #F4F5F7;
  }
  
  .adjustments .ant-table-thead > tr > .ant-table-column-sort {
    background: #e1e7eb !important;
  }
  .adjustments .ant-table-tbody > tr > td.ant-table-selection-column {
    border-right: 1px solid var(--grey);
  }
  
  .adjustments .ant-table-tbody .ant-table-row-selected td {
    background: #e4e9f0 !important;
  }
  
  .adjustments .ant-space > div:first-child > div > div {
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 1px solid rgb(225, 231, 235) !important;
  }
  
  .adjustments .ant-space-item hr {
    height: 1px; 
    border:none; 
    color:rgb(225, 231, 235); 
    background-color:rgb(225, 231, 235);
    margin: 10px 10px;
  }
  
  .adjustments .ant-form.ant-form-horizontal {
    margin-top: 15px;
  }
  
  form#add-adjustment .ant-row:nth-child(3) .ant-space-item {
    margin-bottom: 24px;
  }