#adjustmentDetailsPage {
  z-index: 1;
  background: #f4f5f7;
  padding: 0 20px;

  #edit-adjustment {
    display: flex; 
    flex-direction: column;
  }

  /* important to make sure batch details panel is at the top of the page */
  .batch-details {
    order: -1;
  }

  #projectIdFilter {
    padding: 0;
  }

  hr {
    margin: 10px 0;
  }

  #projectIdFilter .clear-projectId-btn {
    right: 0;
  }
  .penalty-row {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .penalty-delete {
      text-align: right;
      width: 15%;
    }

    .penalty-language {
      width: 25%;
    }

    .penalty-amount {
      width: 30%;
    }

    .penalty-type {
      width: 30%;
      text-align: center;
    }
  }
  .ant-table-selection-extra {
    right: -8px;
    .anticon {
      color: var(--tertiary-color);
    }
  }

  // table fix to be similar to other pages
  .projects-table {
    border: 1px solid var(--grey);
    border-radius: 4px;
    .ant-table-thead > tr > th:first-child {
      width: 3%;
    }
    .ant-table-thead > tr > th:last-child {
      width: 10%;
    }

    .ant-table-thead > tr > th {
      color: var(--primary-color);
      font-weight: bold;
      border-bottom: 1px solid var(--grey);
      padding: 10px !important;
    }

    .ant-table-thead > tr > th.ant-table-selection-column {
      border-right: 1px solid var(--grey);
    }
    .ant-table-thead > tr > .ant-table-column-sort {
      background: #e1e7eb !important;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: unset;
    }

    .ant-table-tbody .ant-table-row-selected:hover {
      background: unset;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: #f4f5f7;
    }

    .ant-table-thead > tr > .ant-table-column-sort {
      background: #e1e7eb !important;
    }

    .ant-table-tbody > tr > td.ant-table-selection-column {
      border-right: 1px solid var(--grey);
    }

    .ant-table-tbody .ant-table-row-selected td {
      background: #e4e9f0 !important;
    }

    .ant-pagination {
      margin: 30px 0;
    }
    .ant-table-column-sorters {
      padding: 0;
    }
    .ant-table-cell {
      padding: 10px;
    }
  }

  .batch-details .ant-descriptions-header {
    margin-bottom: 0;
    padding: 0 3px;
    background: #f4f5f7;
  }
  .batch-details .ant-descriptions-header .ant-descriptions-title {
    font-size: 14px;
    color: var(--primary-color);
  }
  .batch-details
    .ant-descriptions-bordered.ant-descriptions-small
    .ant-descriptions-item-label {
    padding: 2px !important;
    max-width: 90px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .batch-details
    .ant-descriptions-bordered.ant-descriptions-small
    .ant-descriptions-item-content {
    padding: 2px !important;
  }
  .batch-details
    .ant-descriptions-bordered.ant-descriptions-small
    .ant-descriptions-item-content
    svg:hover {
    cursor: pointer;
  }

  .batch-details .ant-collapse-content > .ant-collapse-content-box {
    padding: 6px 16px;
  }
}
