.extension-details-modal .ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #f4f5f7;
}
.extension-details-modal .extension-spinner {
  width: 250px;
  height: 100px;
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.extension-details-modal .ant-spin-dot {
  height: 32px;
  width: 32px;
}
