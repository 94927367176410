.sharing-table {
  border-left: 1px solid var(--grey);
}

.sharing-tab {
  .filters {
    margin: 0;
    border-left: none;
    border-right: none;
  }
  h1 {
    font-size: 20px;
  }
}

.sharing-actions {
  padding: 0 10px 10px 10px;
}