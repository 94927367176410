.userBadgeContainer {
  width: 100%;
  max-width: 300px;
  .TeamsLogo {
    width: 1em;
  }
  p {
    margin-bottom: 0.5em;
  }
  dl {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: unset;
    dt {
      display: flex;
      align-items: center;
      margin: 0 0.5em 0.3em 0;
      &:last-of-type {
        margin-right: unset;
      }
      a {
        margin-left: 0.5em;
      }
    }
  }
}
