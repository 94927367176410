/**** Extension Requests  ****/

.extension-requests h2{
  padding: 0 10px;
}

/* table header  */

.extension-requests .ant-collapse {
    margin-bottom: 20px;
  }
  
  .extension-requests .ant-collapse >.ant-collapse-item >.ant-collapse-header {
    padding: 7px 35px;
    color: var(--primary-color);
  }
  
  .extension-requests .ant-collapse > .ant-collapse-item:last-child,
  .extension-requests .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 4px !important;
  }

  .extension-requests .ant-table-thead > tr > th:last-child {
    width: 10%;
  }
  
  
  .extension-requests .ant-collapse-content {
    border-top: 1px solid var(--grey);
  }
  
  .extension-requests .ant-form-item-label > label {
    color: rgba(0, 0, 0, 0.65);
  }
  
  .extension-requests .ant-form-vertical .ant-form-item-label {
    padding-bottom: 0;
  }
  
  .extension-requests div:nth-child(2) .ant-row {
    padding: 0 !important;
  }
  
  .extension-requests .ant-table-thead > tr > th {
    color: var(--primary-color);
    font-weight: bold;
    border-bottom: 1px solid var(--grey);
  }

  .extension-requests .ant-table-thead > tr > th.ant-table-selection-column{
    border-right: 1px solid var(--grey);
  }

  .extension-requests .ant-table-thead > tr > .ant-table-column-sort {
    background: #e1e7eb !important;
  }

  /* pagination  */
  .extension-requests .ant-pagination {
    margin: 30px 0;
  }
  
  /* table body  */
  .extension-requests .ant-table table{
    border: 1px solid  var(--grey);
    border-radius: 4px;
  }
  
  .extension-requests .ant-table-tbody > tr.ant-table-row:hover > td {
    background: unset;
  }
  
  .extension-requests .ant-table-tbody .ant-table-row-selected:hover {
    background: unset;
  }
  
  .extension-requests .ant-table-tbody > tr.ant-table-row:hover > td  {
    background: #F4F5F7;
  }

  .extension-requests .ant-table-tbody > tr.ant-table-row .ant-anchor-wrapper {
    background: transparent !important;
  }
  
  .extension-requests .ant-table-thead > tr > .ant-table-column-sort {
    background: #e1e7eb !important;
  }
  .extension-requests .ant-table-tbody > tr > td.ant-table-selection-column {
    border-right: 1px solid var(--grey);
  }
  
  .extension-requests .ant-table-tbody .ant-table-row-selected td {
    background: #e4e9f0 !important;
  }

  /* Modal - contact phoenix team to update and change spinner background to this colour */

  .extension-details-modal .ant-descriptions-bordered .ant-descriptions-item-label {
    background: #F4F5F7 !important;
  }