.manage-roles-page {
  padding: 0 10px;
  .ant-table-tbody > tr > td {
    word-break: unset;
  }
}

// roles drawers
.new-role-form,
.edit-role-form {
  .permission-item-wrap {
    margin-bottom: 15px;
    p {
      margin-bottom: 0;
    }
    .permission-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 0;
      .ant-form-item-label {
        padding: 0;
        display: flex;
      }
      .ant-form-item-control {
        .ant-form-item-control-input {
          min-height: 28px;
        }
        .ant-checkbox-group {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  .role-table {
    margin-bottom: 10px;
    .ant-table-title {
      width: 150px;
      position: absolute;
      left: 0;
      z-index: 1;
      font-weight: bold;
      padding: 8px;
      text-transform: capitalize;
    }
    .ant-table-cell {
      padding: 8px;
      .ant-checkbox-wrapper-disabled {
        .ant-checkbox-disabled .ant-checkbox-inner {
          border-color: darkgray !important;
        }
      }
    }
  }
}
