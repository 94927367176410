.manage-pmfee {
    display: flex;
    align-items: center;

    .pm-fee-container {
        margin-right: 10px;
        color: var(--white);
        .title {
            font-weight: bold;
            color: var(--primary-color);
            user-select: none;
        }
    }
}