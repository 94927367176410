.text-search-wrap {
  span:first-of-type {
    display: flex;
    flex-direction: column;
  }
  .filter-label {
    color: var(--tertiary-color);
  }
  .trigger-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .anticon {
      opacity: 0.3;
      margin-top: 3px;
      &:hover {
        color: unset;
      }
      svg {
        font-size: 12px;
      }
    }
  }
}
.textSearch {
  --input-margin: 5px;
  --filter-width: 260px;
  max-width: var(--filter-width);
  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .separator {
    height: 1px;
    background: var(--grey);
    opacity: 0.7;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
    margin: 8px 0 10px 0;
  }
}
