.invoice-submitter {
  width: 80vw !important;
  min-height: 80vh !important;

  .ant-modal-close-x {
    display: none;
  }

  .submission-progress {
    margin: 0 0 20px 0;
  }

  tbody {
    max-height: 50vh;
    overflow: scroll;
  }

  .fetch-plp-error {
    text-align: center;
  }
}
