.manage-quarantined-projects {
  padding: 0 10px;
  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .actions {
      display: flex;
      align-items: center;
    }
  }
}
