.error-wrap {
  min-height: 40vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1,
  h3 {
    user-select: none;
  }
  .actions {
    display: flex;
    margin-top: 40px;
    button {
      margin-right: 15px;
      &.reload {
        svg {
          transition: 0.4s ease-in;
        }
        &:hover {
          svg {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
