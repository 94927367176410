.filters {
  margin: 10px 0;
  padding: 0;
  box-shadow: 0 6px 15px rgb(0 0 0 / 3%);
  transition: 0.3s ease-in;
  width: 100%;
  height: 100%;
  border: 1px solid var(--grey);
  h1 {
    padding: 0;
    margin: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 2.3em;
  }
  .my-filters {
    display: flex;
    align-items: center;
    border: 1px dashed #e0e0e0;
    padding: 8px;
    margin: 0;
    position: relative;
    border-radius: 5px;
    .filters-title {
      color: var(--dark-green);
      margin: 0 10px 0 0;
    }
    .my-filters-buttons {
      button {
        margin-left: 10px;
      }
    }
  }
  .my-filters-buttons {
    display: flex;
    color: var(--tertiary-color);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
  }
  .filters-bar-wrap {
    @keyframes inAnimation {
      0% {
        opacity: 0;
        visibility: hidden;
      }
      100% {
        opacity: 1;
        visibility: visible;
      }
    }

    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    transition: 0.3s ease-in;
    .active-filters {
      display: flex;
      transition: 0.3s ease-in;
      flex-wrap: wrap;
      padding: 3px 0 5px 0;
      .filter {
        display: flex;
        align-items: flex-end;
        margin: 10px 10px 0;
        animation: inAnimation 200ms ease-in;
        bottom: 7px;
        position: relative;
        .remove-filter {
          display: flex;
          align-items: flex-end;
          margin: 0 0 5px 5px;
          animation: inAnimation 200ms ease-in;
          cursor: pointer;
          color: var(--black);
          transition: 0.2s ease-in;
          &:hover {
            color: var(--red);
          }
        }
      }
      .more-trigger {
        margin-top: 20px;
        svg {
          opacity: 0.4;
          margin-top: 3px;
          font-size: 12px;
        }
      }
    }
  }
}
.more-content-wrap {
  --filter-width: 140px;
  min-width: var(--filter-width);
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    .ant-checkbox-wrapper {
      margin-bottom: 5px;
      transition: 0.2s ease-in;
      padding: 2px 4px;
      margin-right: 0;
      &:hover {
        background: #f0f0f0;
      }
    }
  }
  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .uncheck-all {
      padding: 0;
    }
  }

  .separator {
    height: 1px;
    background: var(--grey);
    opacity: 0.7;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
    margin: 8px 0 10px 0;
  }
}
.more-filter-overlay {
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 6%);
  max-height: 370px;
  .ant-popover-inner-content {
    .more-content-wrap {
      .top{
        max-height: 405px;
        overflow-y: scroll;
        overflow-x: auto;
      }
    }
  }
}
