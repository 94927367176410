.finite-filter-wrap {
  .filter-label {
    color: var(--tertiary-color);
  }

  .trigger-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    span {
      &:first-of-type {
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }

    .ant-btn-loading-icon {
      display: flex;
      margin-right: 5px;

      .anticon-loading {
        padding: 0;
        margin-top: 0 !important;
        color: var(--tertiary-color);
      }
    }

    .anticon {
      opacity: 0.4;
      margin-top: 3px;

      svg {
        font-size: 12px;
      }

      &:hover {
        color: unset;
      }
    }
  }

  &.errored {
    .trigger-filter {
      border-color: var(--red);
    }

    label,
    svg,
    .trigger-filter {
      color: var(--red);
    }
  }
}

.ant-select-item {
  .ant-select-item-option-state {
    display: flex;
    align-items: center;
  }
}

.finiteFilter {
  .ant-select {
    max-width: 300px;

    .ant-select-selector {
      max-height: 80px;
      overflow-y: scroll;
    }
  }

  .select-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }

  .lists {
    max-height: 200px;
    height: 100%;
    overflow: auto;

    .list-title {
      color: var(--tertiary-color);
      margin-bottom: 0;
      margin-top: 10px;
      font-weight: bold;

      &__selected {
        display: flex;
        justify-content: space-between;
        margin-right: 6px;
        margin-top: 0;

        .sort-btn {
          cursor: pointer;
          transition: 0.2s ease-in;

          &:hover {
            color: var(--black);
          }
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      min-height: 26px;
      border: 1px dashed #e0e0e0;
      padding: 4px;
      margin-right: 6px;

      .ant-checkbox-group-item {
        display: flex;
        align-items: center;
        margin-right: 0px;
        transition: 0.2s ease-in;
        padding: 2px 4px;

        &:hover {
          background: #F0F0F0;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .separator {
    height: 1px;
    background: var(--grey);
    opacity: 0.7;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
    margin: 8px 0 10px 0;
  }
}