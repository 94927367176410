#add-adjustment, #edit-adjustment {
  hr {
    margin: 10px 0;
  }

  #add-adjustment_newPONumber {
    margin-top: 10px;
  }

  .penalty-row {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .penalty-delete, .penalty-btn {
      text-align: right;
      width: 15%
    }

    .penalty-language {
        width: 25%;
    }

    .penalty-amount {
      width: 30%
    }

    .penalty-type {
      width: 30%;
      text-align: center;
    }
  }
  .ant-table-selection-extra .anticon {
    color: var(--tertiary-color);
  }
  .adj-table-footer {
    padding: 5px;
    dl {
      display: flex;
      grid-template-columns: max-content auto;
    }

    dt {
      grid-column-start: 1;
      font-weight: bold;
      padding-right: 5px;
    }

    dd {
      grid-column-start: 2;
      margin-right: 10px;
      span.adj-table-batch-size-warning {
        color: var(--red)
      }
    }
  }
}
