.progress-title {
  font-weight: 500;
  text-align: left;
  font-size: 14px;
  width: 460px;
  display: block;
}

.progress-line {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 260px;
}

.progress-wrap {
  height: 100%;
  display: flex;
  align-items: center;
  .progress-circle {
    cursor: pointer;
    justify-content: space-between;
    min-width: 100%;
    transform: scale(1);
    transition: 0.2s ease-in;
  }
}

.ant-progress-small.progress-circle .ant-progress-inner {
  width: 49px !important;
  height: 49px !important;
  font-size: 13px !important;
  font-weight: 400;
}

.action-buttons .progress-wrap {
  margin: 0;
}

.actions-bar-container,
.action-buttons {
  .ant-progress-small.progress-circle .ant-progress-inner {
    width: 32px !important;
    height: 32px !important;
    font-size: 10px !important;
    font-weight: 400;
  }
}

.actions-bar-container .ant-progress-circle .ant-progress-text {
  color: var(--white) !important;
}

.ant-progress-circle .ant-progress-text {
  color: var(--primary-color) !important;
}

.show-bar {
  height: 50px;
  opacity: 1;
}

.hide-bar {
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition-property: opacity, height;
  transition-duration: 1s;
  transition-delay: 8s;
  animation-timing-function: ease;
}