.search-project-autocomplete {
  height: 100%;
  margin-right: 20px;
  display: flex;
  align-items: center;
  &:hover {
    background: unset;
  }
  .ant-select-auto-complete {
    .anticon {
      color: var(--primary-color) !important;
    }
    .ant-select-clear {
      opacity: 0.5;
      color: var(--primary-color);
    }
  }
}

// TODO: add a class to the dropdown and nest all css under that class. Once antd has been updated < 4.23.0
.ant-select-dropdown-placement-bottomRight {
  padding-top: 18px;
  .rc-virtual-list-holder {
    max-height: unset !important;
  }
  .rc-virtual-list-scrollbar {
    display: none;
  }
  .rc-virtual-list-holder-inner {
    .ant-select-item-option {
      &-active {
        background: var(--tertiary-color);
        color: var(--white);
      }
    }
  }
}
