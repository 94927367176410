/* Reset CSS Styles  */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
}

body {
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}

h1 {
  font-weight: normal;
  font-size: 22px;
  line-height: 120%;
}

h2 {
  font-weight: normal;
  font-size: 18px;
  line-height: 120%;
}

h3 {
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
}
