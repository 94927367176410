.aside-admin-menu {
  background: var(--white);
  padding-top: 60px;
  .ant-menu {
    .ant-menu-item {
      &:hover {
        background-color: var(--tertiary-color) !important;
        .ant-menu-title-content {
          color: var(--white);
        }
      }
      .ant-menu-title-content {
        padding-left: 8px;
      }
    }
    .ant-menu-item-selected {
      background: var(--primary-color);
      color: var(--white);
    }
  }
  .ant-menu-inline {
    &:first-child {
      padding-top: 25px;
    }
    .ant-menu-sub {
      padding-left: 8px;
    }
    .ant-menu-item {
      height: auto;
      white-space: normal;
      line-height: normal;
      min-height: 40px;
      display: flex;
      align-items: center;
      margin: 4px 0;
      padding-left: 8px !important;
      padding-top: 4px;
      padding-bottom: 4px;
    }
    .ant-menu-submenu-title {
      margin: 0;
      padding-left: 8px !important;
      .ant-menu-submenu-arrow {
        &:after,
        &:before {
          transition: 0.2s ease-in;
        }
      }
      &:hover {
        background: var(--primary-color);
        color: var(--white);
        .ant-menu-submenu-arrow {
          &:after,
          &:before {
            background: var(--white);
          }
        }
      }
    }
  }
}
