.custom-breadcrumb {
  padding: 30px 0 5px 0px;
}

.ant-breadcrumb-separator {
  font-size: 24px;
  margin: 2px 8px;
  display: inline-block;
  vertical-align: middle;
  color: rgba(40, 44, 54, 0.88) !important;
  font-weight: 600;
}

.breadcrumb-item-active {
  color: var(--primary-color);
  font-weight: bold;
  line-height: 1;
  padding-top: 9px;
  display: inline-block;
  vertical-align: middle;
}

.breadcrumb-item {
  color: var(--primary-color);
  cursor: pointer;
  font-size: 20px;
  padding-top: 8px;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
}

.separator {
  margin: 0 0.5rem;
  color: rgba(40, 44, 54, 0.88) !important;
  font-weight: 600;
  font-size: "18px";
}
