.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.loading-wrapper span {
  color: var(--primary-color);
  user-select: none;
  font-size: 80px;
  animation-name: blink;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.loading-wrapper span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-wrapper span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}
