/* Change selected tags color - filter section - invoicing page */

.invoicing-page
  .filters-section
  .ant-select-multiple
  .ant-select-selection-item,
.invoicing-page .filters-section .project-ids-tags .ant-tag {
  background: var(--tertiary-color);
  color: var(--white);
}
.invoicing-page
  .filters-section
  .ant-select-multiple
  .ant-select-selection-item {
  transition: 0.2s ease-in;
}
.invoicing-page
  .filters-section
  .ant-select-multiple
  .ant-select-selection-item:hover {
  opacity: 0.85;
}

.invoicing-page
  .filters-section
  .ant-select-multiple
  .ant-select-selection-item
  .ant-select-selection-item-remove,
.invoicing-page
  .filters-section
  .project-ids-tags
  .ant-tag
  .ant-tag-close-icon {
  color: var(--white);
  transition: 0.2s ease-in;
}
.invoicing-page
  .filters-section
  .ant-select-multiple
  .ant-select-selection-item
  .ant-select-selection-item-remove:hover,
.invoicing-page
  .filters-section
  .project-ids-tags
  .ant-tag
  .ant-tag-close-icon:hover {
  color: var(--black);
}

.invoicing-page
  .filters-section
  .project-ids-tags {
  margin-top: 8px;
}

.invoicing-page .filters-section .project-ids-tags .ant-tag {
  margin-bottom: 4px;
  border: unset;
  height: 24px;
  line-height: 24px;
  font-size: 13px;
}

/**** Projects table  ****/
.invoicing-page .projects-table {
  border: 1px solid var(--grey);
  border-radius: 4px;
}

/* table header  */
.invoicing-page .projects-table .ant-table-thead > tr > th:first-child {
  width: 3%;
}

.invoicing-page .projects-table .ant-table-thead > tr > th:last-child {
  width: 10%;
}

.invoicing-page .projects-table .ant-table-thead > tr > th {
  color: var(--primary-color);
  font-weight: bold;
  border-bottom: 1px solid var(--grey);
  padding: 8px 16px !important;
}

.invoicing-page .projects-table .ant-table-thead > tr > .ant-table-selection-column .ant-table-selection {
  margin-left: -8px;
}

.invoicing-page
  .projects-table
  .ant-table-thead
  > tr
  > th.ant-table-selection-column {
  border-right: 1px solid var(--grey);
}
.invoicing-page .projects-table .ant-table-thead > tr > .ant-table-column-sort {
  background: #e1e7eb !important;
}

/* table body  */
.invoicing-page .projects-table .ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset;
}

.invoicing-page .projects-table .ant-table-tbody .ant-table-row-selected:hover {
  background: unset;
}

.invoicing-page .projects-table .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #f4f5f7;
}

.invoicing-page .projects-table .ant-table-thead > tr > .ant-table-column-sort {
  background: #e1e7eb !important;
}

.invoicing-page
  .projects-table
  .ant-table-tbody
  > tr
  > td.ant-table-selection-column {
  border-right: 1px solid var(--grey);
}

.invoicing-page .projects-table .ant-table-tbody .ant-table-row-selected td {
  background: #e4e9f0 !important;
}

.invoicing-page .project-link {
  transition: 0.2s ease-in;
}
.invoicing-page .project-link:hover {
  color: var(--tertiary-color);
}
.invoicing-page .project-link-icon {
  transition: 0.2s ease-in;
  margin-right: 5px;
  transform: rotate(90deg);
  color: var(--grey);
}
.invoicing-page .project-link-icon:hover {
  color: var(--tertiary-color);
}

/* actions */
.invoicing-page .action-button:hover {
  color: var(--primary-color);
}
.invoicing-page .action-button {
  color: unset;
}
/* custom pagination */
.invoicing-page .projects-table .ant-pagination {
  margin: 30px 0;
}
.invoicing-page .pagination-button {
  font-size: 24px;
  color: var(--tertiary-color);
  transition: 0.2s ease-in;
}
.invoicing-page .pagination-button:hover {
  color: var(--primary-color);
}
