/* Header  */
// responsive
.header-drawer {
  .ant-drawer-body {
    padding: 0;
  }
  .right-menu-container {
    .search-project-autocomplete {
      margin: 10px 0;
      padding: 0 20px;
      .ant-select {
        width: 100%;
      }
    }
    .profile-wrap {
      margin: 10px 0;
      padding: 0 20px !important;
      &:hover {
        background: unset;
      }
    }
    .progressCon {
      margin: 10px 0;
      padding: 0 20px !important;
    }
  }

  .ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
  .ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
    display: inline-block;
    width: 100%;
  }

  .ant-drawer-body .ant-menu-horizontal {
    border-bottom: none;
  }

  .ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
    border-bottom-color: transparent;
  }
  .ant-menu-item {
    padding-left: 16px !important;
    a {
      @media (max-width: 1050px) {
        padding-left: 10px;
      }
    }
  }
}
#main-header {
  z-index: 1051;
  transition: 0.2s ease-in;
  line-height: unset;
  .barsMenu {
    margin: 10px;
    &:hover {
      span {
        background-color: var(--white);
      }
    }
  }
}
.ant-layout-header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 10;
  background-color: var(--white);
  flex-wrap: nowrap;
  padding: unset;
  box-shadow: rgb(0 0 0 / 11%) 0px 0px 10px;
}
.ant-layout-header .ant-row-space-between {
  max-width: var(--content-size);
  margin: 0 auto;
  padding: 0 20px;
}
.ant-layout-header .menu {
  background-color: var(--white);
}
.ant-layout-header .avatar {
  background-color: var(--tertiary-color);
}

/* Custom Antd  */
/* Custom Antd Header */
.rightMenu .menu .ant-menu-item {
  height: 100%;
  .ant-menu-title-content {
    height: 100%;
    .ant-dropdown-trigger {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
.rightMenu .ant-menu-item-selected {
  color: var(--secondary-color) !important;
  background: var(--white) !important;
}

.rightMenu .ant-menu-item-selected .anticon {
  color: unset;
}
.rightMenu .ant-menu-item-active.ant-menu-item-selected .anticon {
  color: var(--white);
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: var(--dark-green);
}

.ant-menu-dark .ant-menu-item > a {
  color: var(--secondary-color);
}

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: var(--white);
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: var(--primary-color);
}

.ant-menu-vertical.ant-menu-sub {
  background-color: var(--primary-color);
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--primary-color);
}
.ant-menu-vertical .ant-menu-item-active:hover {
  background-color: var(--primary-color);
}

/* Admin Menu  */
.admin-menu-wrap {
  color: var(--secondary-color);
  background: var(--white);
  padding: 0 20px;
  user-select: none;
}
.admin-menu-wrap svg {
  margin-left: 5px;
}

.admin-menu-wrap:hover {
  cursor: pointer;
}

.admin-menu-wrap.admin-menu-active {
  color: var(--white);
  background-color: var(--primary-color);
}

.admin-menu-wrap.ant-dropdown-open {
  color: var(--white);
  padding: 0 20px;
}
// fix for responsive menu
.header-drawer .admin-menu-wrap.ant-dropdown-open {
  color: var(--tertiary-color);
}

.admin-menu > .admin-menu-item__active {
  background: var(--primary-color);
}

.admin-menu > .admin-menu-item__active a {
  color: var(--white);
}

.menuBar {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  overflow: hidden;
  transition: 0.2s ease-in;
  border-right: 1px solid var(--grey);
}

.leftContainer {
  display: flex;
  align-items: center;
  height: 100%;
  .logoContainer {
    line-height: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    a {
      flex-flow: column;
      text-transform: capitalize;
    }
    img.logo {
      max-width: fit-content;
      height: 100%;
      max-height: 20px;
      transition: 0.2s ease-in;
    }

    .product-name {
      user-select: none;
      display: flex;
      h1 {
        color: var(--secondary-color);
        font-size: 16px;
        margin: 0 0 0 25px;
      }
    }
  }
}

.menuCon {
  width: 100%;
  height: 100%;
  justify-content: space-between;
  .progressCon {
    float: right;
    height: 100%;
    padding-right: 20px;
  }
  .leftMenu {
    float: left;
    .ant-menu {
      min-width: 700px;
    }
    .ant-menu-item {
      padding: 0 8px;
      &::after {
        inset-inline: 8px;
      }
    }
  }
  .rightMenu {
    min-width: 230px;
    float: right;
    display: flex;
    align-items: center;
    .right-menu-container {
      display: flex;
      align-items: center;
    }
  }

  .leftMenu,
  .rightMenu {
    height: 100%;
    @media (max-width: 1050px) {
      display: none;
    }
    .ant-menu-horizontal {
      height: 100%;
      .ant-menu-submenu {
        display: flex;
        align-items: center;
        padding-inline: 8px;
        a {
          color: var(--black);
        }
        &.ant-menu-submenu-selected {
          a {
            color: var(--tertiary-color);
          }
        }
        &::after {
          bottom: 1px;
        }
      }

      .ant-menu-item {
        height: 100%;
        a {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
      .ant-menu-item-selected {
        a {
          color: var(--tertiary-color);
        }
      }
    }
  }
}

.menuCon .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  width: 180px;
  text-overflow: ellipsis;
}

.menuCon .ant-select-selector {
  margin-right: 5px;
}

.menuCon .ant-menu-dark.ant-menu-horizontal > .ant-menu-item {
  padding: 0px;
  transition: 0.2s ease-in;
}

.menuCon .ant-menu-dark.ant-menu-horizontal > .ant-menu-item a {
  padding: 10px;
}

.menuCon > .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 8px;
}

.menuCon .ant-menu-item .anticon {
  margin-right: 0;
}

.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 0;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.menuCon .menu-no-pad {
  padding: 0 !important;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: none;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: var(--tertiary-color);
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  background: var(--tertiary-color);
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.barsMenu > span {
  display: block;
  margin-bottom: 5px;
  transition: 0.2s ease-in;
  &:last-child {
    margin-bottom: 0;
  }
}

.main-header.slimmer {
  height: var(--small-header-bar-size);
  line-height: 40px;
  .leftContainer .logoContainer .product-name {
    top: -11px;
    position: relative;
    transition: 0.2s;
  }

  .leftContainer .logoContainer a {
    width: 26px;
    overflow: hidden;
    top: 9px;
    position: relative;
    transition: 0.2s;
  }

  .progressCon {
    sup.ant-scroll-number {
      top: 10px !important;
      right: 2px !important;
    }
  }

  .menuCon {
    .progress-wrap {
      .progress-circle {
        transform: scale(0.7);
        margin-top: -5px;
      }
    }
    .rightMenu {
      .ant-avatar {
        transform: scale(0.8);
      }
      .ant-badge-count {
        top: 5px;
      }
    }
  }
}

@media (max-width: 1050px) {
  .menuCon {
    justify-content: end;
  }
  .barsMenu {
    display: inline-block;
    box-shadow: unset;
  }

  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .leftContainer {
    .logoContainer {
      display: inline-block;
      flex-flow: column;
      transition: 0.2s ease-in;
    }
  }

  .slimmer {
    .leftContainer {
      .logoContainer {
        a {
          top: 2px !important;
        }
      }
    }
    .product-name {
      h1 {
        display: none;
      }
    }
  }
}

.search-dropdown-menu {
  .ant-menu-item {
    &:hover{
      background: var(--tertiary-color)!important;
      color: var(--white)!important;
    }
    &-selected {
      background: var(--primary-color)!important;
      color: var(--white)!important;
      &:hover{
        background: var(--tertiary-color)!important;
      }
    }
  }
}