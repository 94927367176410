.auto-upload-modal {
  .ant-modal-header {
    user-select: none;
  }
  .ant-modal-content {
    .subtitle {
      padding-bottom: 6px;
      border-bottom: 1px solid #e8e8e8;
      margin-bottom: 6px;
    }
    .question {
      font-weight: bold;
      padding-top: 3px;
      margin-top: 10px;
      border-top: 1px solid #e8e8e8;
    }
    .all-matches {
      max-height: 500px;
      overflow-x: auto;
      .single-match {
        padding-bottom: 15px;
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: 6px;
        &__filename {
          padding: 2px 0;
          span {
            font-weight: bold;
            margin-right: 5px;
            color: var(--primary-color);
            user-select: none;
          }
        }
        &__table {
          border: 1px dashed var(--primary-color);
          margin-top: 2px;
          .ant-table {
            .ant-table-thead {
              .ant-table-cell {
                padding: 0;
              }
            }
            .ant-table-tbody {
              .ant-table-row {
                .ant-table-cell {
                  padding: 2px 8px;
                }
              }
            }
          }
          .ant-empty {
            margin: 0;
            min-height: unset;
            .ant-empty-image {
              display: none;
            }
          }
        }
        &:last-of-type {
          border-bottom: unset;
        }
      }
    }
  }
}
