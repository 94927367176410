.logo-svg {
    width: 50px;
    height: 50px;
    animation: fadeIn 2.1s ease-in-out;
    &.animate {
        .__logo-square-tr {
            transition-timing-function: ease-in;
            transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
            animation: rotate-tr 1.6s infinite;
            //display: none;
        }

        .__logo-square-bl {
            transition-timing-function: ease-out;
            transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
            animation: rotate-bl 1.6s infinite;
            //display: none;
        }

        .__logo-square-tl {
            transition-timing-function: ease-in-out;
            transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
            animation: rotate-tl 1.6s infinite;
            //display: none;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@keyframes rotate-tl {
    0% {
        transform: translateY(0%) translateX(0%);
    }
    15% {
        transform: translateY(0%) translateX(50%);
    }
    25% {
        transform: translateY(50%) translateX(50%);
    }
    37.5% {
        transform: translateY(50%) translateX(0%);
    }
    47% {
        transform: translateY(0%) translateX(0%);
    }
}

@keyframes rotate-tr {
    0% {
        transform: translateY(0%) translateX(0%);
    }
    11% {
        transform: translateY(50%) translateX(0%);
    }
    25% {
        transform: translateY(50%) translateX(-50%);
    }
    37.5% {
        transform: translateY(0%) translateX(-50%);
    }
    75% {
        transform: translateY(0%) translateX(0%);
    }
}

@keyframes rotate-bl {
    0% {
        transform: translateY(0%) translateX(0%);
    }
    12.5% {
        transform: translateY(-50%) translateX(0%);
    }
    20% {
        transform: translateY(-50%) translateX(50%);
    }
    35% {
        transform: translateY(0%) translateX(50%);
    }
    60% {
        transform: translateY(0%) translateX(0%);
    }
}
