.c-multi-drag-table.is-dragging table {
  border: 1px solid #4285f4;
}
.c-multi-drag-table .ant-col {
  position: relative;
}

.c-multi-drag-table .inner-col {
  position: relative;
  height: 100%;
  background: var(--white);
  border: 1px solid #f0f0f0;
  padding: 10px;
}
.c-multi-drag-table .ant-table {
  border: 1px solid #f0f0f0;
}
.c-multi-drag-table .ant-table-thead > tr > th,
.c-multi-drag-table .ant-table-tbody > tr > td,
.c-multi-drag-table .ant-table tfoot > tr > th,
.c-multi-drag-table .ant-table tfoot > tr > td {
  padding: 6px 16px;
}
.c-multi-drag-table .ant-table-tbody > tr.ant-table-placeholder > td,
.c-multi-drag-table .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item {
  background: url("../assets/images/drag-handle.png") no-repeat 15px 10px var(--white);
}
.c-multi-drag-table
  .ant-table-tbody.is-dragging-over
  > tr.ant-table-placeholder {
  background: url("../assets/images/drag-handle.png") no-repeat 15px 10px
    #e4e9f0 !important;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item.row-selected {
  background: url("../assets/images/drag-handle.png") no-repeat 15px 10px
    #e4e9f0;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item.row-dragging {
  display: table;
  color: var(--white);
  background: url("../assets/images/drag-handle-white.png") no-repeat 15px 10px
    var(--tertiary-color);
  border: 1px solid #f0f0f0;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item.row-dragging > td {
  border-bottom: 0;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item.row-ghosting {
  opacity: 0.5;
}

.c-multi-drag-table .ant-table {
  z-index: unset;
  height: 430px;
  overflow-y: auto;
}

.c-multi-drag-table .ant-table-thead > tr > th,
.c-multi-drag-table .ant-table-tbody > tr > td {
  text-transform: capitalize;
}

.product-assignment .ant-table-column-sorters {
  padding: 0;
}

.product-assignment td.ant-table-column-sort {
  background: transparent;
}
