.boolean-filter-wrap {
  .filter-label {
    color: var(--tertiary-color);
  }
  .trigger-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    span {
      &:first-of-type {
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
    .anticon {
      opacity: 0.4;
      margin-top: 3px;
      svg {
        font-size: 12px;
      }
      &:hover {
        color: unset;
      }
    }
  }
}
.booleanFilter {
  --margin: 5px;
  --filter-width: 140px;
  min-width: var(--filter-width);
  .top {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin-bottom: var(--margin);
      }
    }
  }
  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .separator {
    height: 1px;
    background: var(--grey);
    opacity: 0.7;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
    margin: 8px 0 10px 0;
  }
}
