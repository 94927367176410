.document-word-counts-wizard-wrap {
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
  }

  .error {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
  }

  .error-icon-large {
    display: block;
    color: #f5222d;
    font-size: 75px;
  }

  .step-content {
    padding: 10px;
    border: 1px solid;
    border-color: var(--grey);
    border-radius: 5px;
    margin-top: 10px;
    height: 400px;
    max-height: 400px;
    overflow-y: scroll;
  }

  .icon-button {
    margin-left: 3px;
    color: var(--primary-color);
    padding: 0;
    cursor: pointer;
    transition: 0.2s ease-in;
    &:hover {
      transform: scale(1.2);
    }
  }

  .list-form {
    .list-form-header-row {
      font-weight: bold;
      margin-bottom: 6px;
    }

    .list-form-row {
      padding: 4px 0;
      border-top: 1px solid;
      border-color: var(--grey);
    }

    .list-form-item {
      margin-bottom: 0;
    }

    .ant-col {
      display: flex;
      align-items: center;
    }
  }
  .last-step-container {
    p {
      &:first-of-type {
        margin-bottom: 0;
      }
    }
    &__unmatched {
      margin-top: 5px;
      padding-top: 20px;
      border-top: 1px dashed var(--tertiary-color);
    }
  }
}
