.TeamsLogo {
  // enable-background: new 0 0 2458 2458; // deprecated
  margin-bottom: -2px;
  .st0{fill:#7B83EB;}
  .st1{opacity:0.1;enable-background:new    ;}
  .st2{opacity:0.2;enable-background:new    ;}
  .st3{fill:#5059C9;}
  .st4{fill:#4B53BC;}
  .st5{fill:#FFFFFF;}
  .st6{fill:none;}
}
