.profile-wrap {
  border: unset;
  background: transparent;
  transition: 0.2s ease-in;
  &:hover {
    filter: opacity(0.8);
  }
  .ant-badge-count {
    border-radius: 3px;
  }
}

.profile-menu {
  width: 320px !important;
  .ant-dropdown-menu {
    padding-top: 20px;
    .ant-dropdown-menu-item {
      padding: 5px;
      &:hover {
        background: unset;
      }
      .ant-dropdown-menu-title-content {
        button {
          width: 100%;
          text-align: left;
          border-radius: 0;
        }
      }
    }
    .ant-collapse {
      border: unset;
      background: transparent;
      &:hover {
        color: rgba(40, 44, 54, 0.88);
        background: rgba(40, 44, 54, 0.06);
      }
      .ant-collapse-item {
        border-bottom: unset;
        &-active {
          color: rgba(40, 44, 54, 0.88);
          background: rgba(40, 44, 54, 0.06);
        }
      }
      .ant-collapse-header {
        padding: 5px 16px;
      }
      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 0;
          .admin-link {
            transition: 0.2s ease-in;
            padding: 6px 14px;
            cursor: pointer;
            border: unset;
            &:hover {
              background: var(--primary-color);
              color: var(--white);
            }
            &-active {
              background: var(--primary-color);
              color: var(--white);
            }
          }
        }
      }
    }
  }
}
