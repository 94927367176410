/* Collapse header  */
.project-details-content
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  font-weight: bold;
  background-color: #f4f5f7;
  color: var(--primary-color);
  border-radius: 4px 0 0 0;
  user-select: none;
  padding: 7px 35px;
}

.project-details-body .ant-table-column-sorters {
  padding: 0;
}
.project-details-body .ant-collapse {
  border: 1px solid var(--grey);
}
.project-details-body .ant-collapse-content {
  border-top: 1px solid var(--grey);
}
.project-details-body .ant-collapse > .ant-collapse-item {
  border-bottom: unset;
}

/* General Information */
.project-details-body .general-information .ant-descriptions-header {
  margin-bottom: 0;
  padding: 0 3px;
  background: #f4f5f7;
}
.project-details-body
  .general-information
  .ant-descriptions-header
  .ant-descriptions-title {
  font-size: 14px;
  color: var(--primary-color);
}
.project-details-body
  .general-information
  .ant-descriptions-bordered.ant-descriptions-small
  .ant-descriptions-item-label {
  padding: 2px !important;
  max-width: 90px;
  font-weight: bold;
  text-transform: uppercase;
}

.project-details-body
  .general-information
  .ant-descriptions-bordered.ant-descriptions-small
  .ant-descriptions-item-content {
  padding: 2px !important;
}
.project-details-body
  .general-information
  .ant-descriptions-bordered.ant-descriptions-small
  .ant-descriptions-item-content
  svg:hover {
  cursor: pointer;
}

.project-details-body
  .general-information
  .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 6px 16px;
}

/* Language pairs */
.project-details-body .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

/* Tables header  */
.project-details-body .ant-table-thead > tr > th {
  color: var(--primary-color);
  font-weight: bold;
  border-bottom: 1px solid var(--grey);
  padding: 10px !important;
  text-align: center;
  user-select: none;
}

.project-details-body .ant-table-thead > tr > th.ant-table-selection-column {
  padding: 10px 5px !important;
}

.project-details-body .ant-table-thead > tr > .ant-table-column-sort {
  background: #e1e7eb !important;
}

/* Tables body  */
.project-details-body .ant-table-tbody > tr > td {
  padding: 10px !important;
  text-align: center;
}

.project-details-body .ant-table-tbody > tr > td.ant-table-selection-column {
  padding: 10px 5px !important;
}

.project-details-body .ant-table-tbody .ant-table-row-selected td {
  background: #e4e9f0 !important;
}

/* scroll up button  */
.project-details-body .scroll-up {
  font-size: 24px;
  color: var(--white);
  background: var(--primary-color);
  border: 1px solid var(--dark-green-color);
  border-radius: 5px;
  padding: 5px;
  transition: 0.2s ease-in;
}

.project-details-body .scroll-up:hover {
  background: var(--primary-color);
}

/* Dtp Files table */
.dtp-files .ant-list-item {
  padding: 4px;
}
.dtp-files .ant-list-item-action {
  margin-left: 10px;
}
.dtp-files .ant-list-item-action li {
  padding: 0;
  margin-left: 4px;
}
.dtp-files .ant-list-item-action li:first-of-type {
  margin-left: 0px;
}

.project-details-body .dtp-files .ant-table-tbody > tr > td {
  padding: 0px !important;
}

.dtp-files
  .ant-table-thead
  tr
  th.ant-table-column-has-sorters
  .ant-table-filter-column-title {
  padding: 0;
}

/* General Information */
.general-information
  .ant-descriptions-bordered.ant-descriptions-small
  .ant-descriptions-item-label {
  padding: 2px !important;
}

.general-information
  .ant-descriptions-bordered.ant-descriptions-small
  .ant-descriptions-item-content {
  padding: 2px !important;
}

.general-information .ant-collapse-content > .ant-collapse-content-box {
  padding: 6px 16px;
}

.general-information .download-all-icon {
  color: var(--tertiary-color);
  font-size: 16px;
  padding: 5px 5px 0;
  border: none;
}
.general-information .download-all-icon:hover {
  cursor: pointer;
}
.general-information .delete-all-icon {
  color: var(--red);
  font-size: 16px;
  padding: 0 5px;
  border: none;
  border-left: 1px solid var(--grey);
  border-radius: 0;
}
.general-information .delete-all-icon:hover {
  cursor: pointer;
}

.general-information .reference-table tbody tr {
  max-height: 26px;
}
.general-information .reference-table td.reference-file {
  min-width: 69px;
}

.general-information th.reference-file {
  padding-bottom: 0;
  padding-left: 5px !important;
  max-width: unset !important;
  width: 30%;
  font-weight: normal !important;
  text-transform: none !important;
}
.general-information td.reference-file {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f5f7;
  width: unset;
  padding: 0px !important;
}

.general-information .reference-file .download-single-icon {
  color: var(--primary-color);
  font-size: 16px;
  padding: 0 5px;
}

.general-information .show-button {
  margin: 5px 0 5px 0;
}

.general-information .show-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Language pairs */
.language-pairs .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

/* Language Pairs table header  */

.language-pairs .ant-table-thead > tr > th {
  color: var(--primary-color);
  font-weight: bold;
  border-bottom: 1px solid var(--grey);
  padding: 10px 5px 10px 5px !important;
  text-align: center;
}

.language-pairs .ant-table-thead > tr > th.ant-table-selection-column {
  border-right: 1px solid var(--grey);
  padding: 10px 5px !important;
}

.language-pairs .ant-table-thead > tr > .ant-table-column-sort {
  background: #e1e7eb !important;
}

/* Language Pairs table body  */
.language-pairs .ant-table-tbody > tr > td {
  padding: 10px !important;
  text-align: center;
}

.language-pairs .ant-table-tbody > tr > td.ant-table-selection-column {
  border-right: 1px solid var(--grey);
  padding: 10px 5px !important;
}

/* scroll up button  */
.project-details-body .scroll-up {
  font-size: 24px;
  color: var(--white);
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: 5px;
  transition: 0.2s ease-in;
}
.project-details-body .scroll-up:hover {
  background: var(--primary-color);
}

/* change status button  */
.select-change-status .ant-select-selector {
  background-color: var(--primary-color) !important;
}
.select-change-status .ant-select-selector .ant-select-selection-item {
  color: var(--white) !important;
}
.select-change-status .ant-select-selector:hover {
  background-color: var(--dark-green) !important;
}
.select-change-status .ant-select-selector .ant-select-selection-placeholder,
.select-change-status .ant-select-arrow {
  color: var(--white);
}

/*  update status modal  */
.update-status-modal .ant-modal-header {
  user-select: none;
  border: none;
}
.update-status-modal .ant-modal-header .ant-modal-title {
  font-weight: bold;
  font-size: 18px;
}
.update-status-modal .duration-input-wrap {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}

.update-status-modal .ant-modal-body {
  padding: 0;
  margin: 0 15px 10px;
}
.update-status-modal .ant-modal-body .ant-spin-dot {
  font-size: 16px;
}
.update-status-modal .ant-modal-body .ant-spin-dot .ant-spin-dot-item {
  width: 8px;
  height: 8px;
}
.update-status-modal .ant-modal-body .ant-table-content {
  border: 1px solid var(--grey);
}
.update-status-modal .ant-modal-footer {
  border: none;
  text-align: center;
}

.update-status-modal .editable-cell {
  position: relative;
}

.update-status-modal .editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
  text-align: center;
}

.update-status-modal .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 10px;
  max-width: 90px;
}

[data-theme="dark"]
  .update-status-modal
  .editable-row:hover
  .editable-cell-value-wrap {
  border: 1px solid var(--black);
}

.modal-table .ant-table-small .ant-table-thead > tr > th {
  font-weight: bold;
  border-bottom: 1px solid var(--grey);
  text-align: center;
}
.modal-table .ant-table-small .ant-table-tbody > tr > td {
  padding: 3px 5px;
  text-align: center;
}
.apply-all-btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* tabs */
.project-details-content {
  .ant-tabs {
    border-radius: 4px 4px 0 0;
    border: 1px solid var(--grey);
    padding-bottom: 10px;
    background: var(--white);
  }
  .ant-tabs-content-holder {
    transition: 0.3s ease-in;
  }
  .ant-tabs-nav {
    padding: 7px 15px 0 15px;
    margin: 0 0 10px 0;
    background: var(--white);
    border-bottom: 1px solid var(--grey);
    .ant-tabs-tab {
      padding: 8px 14px;
      margin-left: 0px;
      transition: 0.3s ease-in;
      &.ant-tabs-tab-active {
        background: var(--primary-color);
        border-radius: 4px;
        .ant-tabs-tab-btn {
          color: var(--white);
        }
      }
      .anticon {
        margin-right: 4px;
      }
    }
  }
}

/* Helix integration  */
.project-details-body .ownership-table {
  .userBadgeContainer {
    max-width: unset;
    .top-wrap {
      .ant-avatar {
        width: 25px !important;
        height: 25px !important;
        line-height: 25px !important;
        min-width: 25px;
        user-select: none;
        .ant-avatar-string {
          line-height: 22px !important;
          transform: unset !important;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          left: unset;
          font-size: 14px;
        }
      }
      .details-wrap {
        flex-wrap: wrap;
        strong {
          margin-right: 4px;
        }
      }
    }
  }
}

.project-details-body .captionhub-table .ant-table-thead > tr > th,
.project-details-body .helix-integration-table .ant-table-thead > tr > th,
.project-details-body .ownership-table .ant-table-thead > tr > th {
  font-weight: bold;
  background-color: #f4f5f7;
  color: var(--primary-color);
  border-radius: 4px 0 0 0;
  user-select: none;
  padding: 8px !important;
}
.project-details-body .captionhub-table .ant-table-tbody > tr > td,
.project-details-body .helix-integration-table .ant-table-tbody > tr > td,
.project-details-body .ownership-table .ant-table-tbody > tr > td {
  padding: 7px !important;
}

.project-details-body
  .helix-integration-table
  .ant-table-thead
  > tr
  > th:nth-child(2) {
  text-align: left;
}

.project-details-body
  .helix-integration-table
  .ant-table-tbody
  > tr
  > td:nth-child(2) {
  text-align: left;
}

.project-details-body .captionhub-table,
.project-details-body .helix-integration-table,
.project-details-body .ownership-table {
  border: 1px solid var(--grey) !important;
  border-radius: 4px;
}

.project-details-body .captionhub-table .ant-table-thead > tr > th,
.project-details-body .captionhub-table .ant-table-tbody > tr > td,
.project-details-body .ownership-table .ant-table-thead > tr > th,
.project-details-body .ownership-table .ant-table-tbody > tr > td {
  text-align: left;
}

.qa-descriptions {
  margin-top: 5px;
  border: 1px solid #e1e7eb;

  .ant-descriptions-title {
    font-weight: bold;
    color: var(--primary-text-color);
  }

  .ant-descriptions-extra {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.qa-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 8px;
  padding: 8px;

  .qa-item {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 4px;
    padding: 8px 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    .qa-question {
      font-weight: bold;
      color: var(--primary-text-color);
      word-break: break-word;
      white-space: pre-wrap;
    }

    .qa-answer {
      color: var(--secondary-text-color);
      max-height: 90px;
      overflow-y: auto;
      word-break: break-word;
      white-space: pre-wrap;
    }
  }
}

.qa-empty {
  font-size: 14px;
}
