/**** Users table  ****/

/* table header  */
.users-table .ant-table-thead > tr > th {
  color: var(--primary-color);
  font-weight: bold;
  border-bottom: 1px solid var(--grey);
}
.users-table .ant-table-thead > tr > th.ant-table-selection-column {
  border-right: 1px solid var(--grey);
}
.users-table .ant-table-thead > tr > .ant-table-column-sort {
  background: #e1e7eb !important;
}
/* pagination  */
.users-table .ant-pagination {
  margin: 30px 0;
}

/* table body  */
.users-table .ant-table table {
  border: 1px solid var(--grey);
  border-radius: 4px;
}

.users-table .ant-table-tbody > tr.ant-table-row:hover > td{
  background: unset;
}

.users-table .ant-table-tbody .ant-table-row-selected:hover {
  background: unset;
}

.users-table .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #F4F5F7;
}

.users-table .ant-table-thead > tr > .ant-table-column-sort {
  background: #e1e7eb !important;
}

.users-table .ant-table-tbody > tr > td.ant-table-selection-column {
  border-right: 1px solid var(--grey);
}

.users-table .ant-table-tbody .ant-table-row-selected td {
  background: #e4e9f0 !important;
}

