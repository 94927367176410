.list-filter-wrap {
  .filter-label {
    color: var(--tertiary-color);
  }
  .trigger-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    span {
      &:first-of-type {
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
    .anticon {
      opacity: 0.4;
      margin-top: 3px;
      svg {
        font-size: 12px;
      }
      &:hover {
        color: unset;
      }
    }
  }
}
.listfilter {
  --input-margin: 5px;
  --filter-width: 260px;
  max-width: var(--filter-width);
  display: block;
  width: 100%;
  padding: 0;
  .top-wrap {
    display: flex;
    align-items: center;
  }
  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .separator {
    height: 1px;
    background: var(--grey);
    opacity: 0.7;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
    margin: 8px 0 10px 0;
  }
  .label {
    display: flex;
    align-items: center;
  }
  .itemsFiltered {
    border: 1px solid rgb(225, 231, 235);
    margin-top: 20px;
    border-radius: 4px;
    height: 100%;
  }
  .clear-item-btn {
    border: none;
    background: transparent;
    position: absolute;
    right: 20px;
  }
}
