.captionhub-wizard-wrap {
  
  .ant-steps-item-description {
    max-width: unset !important;
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
  }

  .error {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
  }

  .error-icon-large {
    display: block;
    color: #f5222d;
    font-size: 75px;
  }

  .step-content {
    border: 1px solid;
    border-color: var(--grey);
    border-radius: 5px;
    margin-top: 10px;
    overflow-y: auto;
    max-height: 480px;
  }

  .icon-button {
    margin-left: 3px;
    color: var(--primary-color);
    padding: 0;
    cursor: pointer;
    transition: 0.2s ease-in;
    &:hover {
      transform: scale(1.2);
    }
  }
  
  .list-form {
    .list-form-header-wrap {
      padding: 10px 10px 0 10px;
      .list-form-header-row {
        padding-bottom: 10px;
        .project-title {
          font-size: 16px;
          line-height: 2;
        }
        .list-form-item {
          margin-bottom: 0px;
        }
      }
    }
    .list-form-label-switch, .list-form-label  {
      margin-top: 5px;
    }
    .list-form-row {
      padding: 15px 10px 15px 10px;
      border-top: 1px solid;
      border-color: var(--grey);
      width: 100%;
      .list-form-item {
        margin-bottom: 0px;
      }
    }
  }
  .last-step-container {
    p {
      &:first-of-type {
        margin-bottom: 0;
      }
    }
    &__unmatched {
      margin-top: 5px;
      padding-top: 20px;
      border-top: 1px dashed var(--tertiary-color);
    }
  }
}
