.date-filter-wrap {
  display: flex;
  flex-direction: column;

  .filter-label {
    color: var(--tertiary-color);
  }
  .trigger-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    span {
      &:first-of-type {
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
    .anticon {
      svg {
        opacity: 0.4;
        margin-top: 3px;
        font-size: 12px;
      }
    }
  }
}

.date-filter-content {
  --input-margin: 5px;
  .top {
    .options-wrapper {
      display: grid;
      grid-template-rows: 0.5fr 0.5fr 1fr;
      .option {
        margin: 0 0 var(--input-margin) 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .ant-picker-input,
        .ant-select {
          width: calc(var(--filter-width) / 2);
        }
      }
    }
    .select-wrapper {
      .option {
        margin: 0 0 var(--input-margin) 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .ant-input-search {
          margin: 0 5px 0 5px;
        }
      }
    }
  }

  .relative-dates {
    input[type="button"] {
      cursor: pointer;
    }
    .ant-input-search,
    .ant-select {
      width: 100%;
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .separator {
    height: 1px;
    background: var(--grey);
    opacity: 0.7;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
    margin: 8px 0 10px 0;
  }
  .label {
    margin: -8px 8px 0 0;
  }

  .top {
    .select-wrapper {
      .option {
        .ant-input-search {
          margin: 0 5px 0 5px;
        }
      }
    }
  }
}
