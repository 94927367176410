.check-rate-match-modal {
  .ant-select {
    .anticon-spin {
      svg {
        fill: var(--primary-color);
      }
    }
  }
}

.rate-match-results-modal {
  .ant-table-wrapper {
    border: 1px solid var(--grey);
    border-radius: 4px;
    .ant-table-thead {
      tr {
        .ant-table-cell {
          border-bottom: 1px solid var(--grey);
          color: var(--primary-color);
          font-weight: bold;
        }
      }
    }
  }
}
