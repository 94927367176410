.content-wrap {
  height: 100%;
  width: 100%;
  margin-top: var(--header-bar-size);
  overflow-x: unset!important;
}

/* back top button  */
.back-top-btn {
  font-size: 22px;
  color: var(--white);
  background: var(--primary-color);
  border: 1px solid var(--dark-green);
  border-radius: 5px;
  padding: 4px;
  transition: 0.2s ease-in;
}
.back-top-btn:hover {
  background: var(--dark-green);
}

/* No data  */
.ant-empty {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-empty .ant-empty-description {
  color: var(--primary-color);
}

/* Fix for long cells content */
.ant-table-tbody > tr > td {
  word-break: break-word;
}
