/**** Linguist Management  ****/

.linguist-management h2{
  padding: 0 10px;
}

.linguist-management .ant-space-item{
  padding: 0 10px;
}
.linguist-management .ant-space-item:last-of-type{
  padding: 0;
}

/* table header  */

.linguist-management .ant-collapse {
  margin-bottom: 20px;
}

.linguist-management .ant-collapse >.ant-collapse-item >.ant-collapse-header {
  padding: 7px 35px;
  color: var(--primary-color);
}

.linguist-management .ant-collapse > .ant-collapse-item:last-child,
.linguist-management .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 4px !important;
}

.linguist-management .ant-collapse-content {
  border-top: 1px solid var(--grey);
}

.linguist-management .ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.65);
}

.linguist-management .ant-form-vertical .ant-form-item-label {
  padding-bottom: 0;
}

.linguist-management div:nth-child(2) .ant-row {
  padding: 0 !important;
}

.linguist-management .ant-table-thead > tr > th {
  color: var(--primary-color);
  font-weight: bold;
  border-bottom: 1px solid var(--grey);
  padding: 10px 8px !important;
}
.linguist-management .ant-table-thead > tr > th.ant-table-selection-column{
  border-right: 1px solid var(--grey);
}
.linguist-management .ant-table-thead > tr > .ant-table-column-sort {
  background: #e1e7eb !important;
}
/* pagination  */
.linguist-management .ant-pagination {
  margin: 30px 0;
}

/* table body  */
.linguist-management .ant-table table{
  border: 1px solid  var(--grey);
  border-radius: 4px;
}

.linguist-management .ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset;
}

.linguist-management .ant-table-tbody .ant-table-row-selected:hover {
  background: unset;
}

.linguist-management .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #F4F5F7;
}

.linguist-management .ant-table-thead > tr > .ant-table-column-sort {
  background: #e1e7eb !important;
}
.linguist-management .ant-table-tbody > tr > td.ant-table-selection-column {
  border-right: 1px solid var(--grey);
}

.linguist-management .ant-table-tbody .ant-table-row-selected td {
  background: #e4e9f0 !important;
}

.linguist-management .ant-space > div:first-child > div > div {
  margin-left: 0 !important;
  margin-right: 0 !important;
  border: 1px solid rgb(225, 231, 235) !important;
}

.linguist-management .ant-space-item hr {
  height: 1px; 
  border:none; 
  color:rgb(225, 231, 235); 
  background-color:rgb(225, 231, 235);
  margin: 10px 10px;
}