.not-found-page {
  width: 100%;
  height: calc(100vh - 40px);
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    min-height: 100%;
    margin: 0 auto;
    position: relative;
    .logo {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        img {
          max-width: 100px;
        }
        h1 {
          font-size: 18px;
          padding-left: 28px;
          user-select: none;
          color: var(--primary-color);
        }
      }
      .intro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        user-select: none;
        margin: -40px 0 40px 0;
      }
  }
}
