@import "./styles/typography.css";
@import "./styles/animations.css";

@import "./main/content.scss";

@import "./ui/loading.css";
@import "./components/projectDetailsPage/details-page.scss";
@import "./components/invoicing/invoicing-page.css";
@import "./styles/styles-drag-drop.css";
@import "./styles/linguist-management.css";
@import "./styles/adjustments.css";
@import "./styles/extension-requests.css";
@import "./styles/users.css";
@import "./components/managePages/manage-pages.css";
@import "./containers/upload-button.css";

@import "./components/invoicingProjectDetailsPage/invoicing-details-page.css";

@import "./styles/reset-antd.scss";

.App {
  height: 100%;
}

.layout {
  min-height: 100vh;
  height: 100%;
}