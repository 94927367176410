.owner-assign-dropdown {
  border-width: "1px";
  border-style: "solid";
  border-color: "var(--tertiary-color)";
  position: "fixed";
  z-index: "99999";
  .ant-dropdown-menu {
    padding-bottom: 8px;
    .ant-dropdown-menu-item {
      padding: 0;
      button {
        border: unset;
        width: 100%;
        height: 100%;
        background: transparent;
        padding: 5px;
        cursor: pointer;
        transition: 0.2s ease-in;
        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
}
