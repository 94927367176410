.sharing-attempts-modal {
  .ant-modal-content {
    padding-left: 15px;
    padding-right: 15px;
    .ant-modal-body {
      margin-top: 20px;
      height: 500px;
      overflow-y: auto;
    }
    .ant-modal-header {
      .ant-modal-title {
        color: var(--primary-color);
      }
    }
    .response-column {
      &__failure {
        color: var(--red);
      }
      &__success {
        color: var(--primary-color);
      }
    }
  }
  .ant-modal-footer {
    margin-top: 0;
  }
}
