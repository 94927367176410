.abstract-table {
  width: 100%;
  color: var(--black);

  @keyframes slowFade {
    0% {
      opacity: 100%;
    }
    25% {
      opacity: 65%;
      background: #cef5ce;
    }
    50% {
      opacity: 100%;
    }
    75% {
      opacity: 65%;
      background: #cef5ce;
    }
    100% {
      opacity: 100%;
    }
  }

  .BaseTable__row {
    &.rowUpdated {
      animation: slowFade 6s ease-in-out;
      &:hover {
        background: #cef5ce;
      }
    }
  }

  // Old ANTD table
  .projects-table {
    border: 1px solid var(--grey);

    td,
    th {
      background: var(--white);
      white-space: nowrap;
    }

    .ant-table-header {
      table {
        table-layout: fixed !important;
      }
    }

    .ant-table-thead {
      tr {
        th {
          border-bottom: 1px solid var(--grey);
          font-weight: bold;
          color: var(--primary-color);
        }
      }
    }

    .ant-table-body {
      min-height: 90vh;
      background: #f0f1f3;
      table {
        table-layout: fixed !important;
        thead > tr:first-child th:first-child {
          border-right: 1px solid var(--grey);
          border-radius: 0;
        }
      }
      tr {
        &:last-of-type {
          td {
            border-bottom: 1px solid var(--grey);
          }
        }
      }
    }

    .ant-table-tbody > tr > td.ant-table-selection-column {
      border-right: 1px solid var(--grey);
    }

    .ant-table-column-sorter-full {
      display: flex;
    }

    .disabled-row {
      color: rgba(0, 0, 0, 0.5);
      .ant-checkbox-disabled .ant-checkbox-inner {
        border-color: var(--grey) !important;
      }
      .ant-checkbox-disabled .ant-checkbox-inner:before {
        content: "x";
        color: var(--red);
        font-weight: bold;
      }
    }

    .ant-table-tbody > .disabled-row:hover > td {
      background: var(--white) !important;
      cursor: not-allowed;
      user-select: none;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: #cef5ce;
    }

    .ant-table-tbody > tr.ant-table-row.ant-table-row-selected:hover > td {
      background: #f5f5dcff;
    }

    .metaRow {
      &.warning,
      &.critical {
        td:first-of-type {
          &:before {
            height: 100%;
            display: block;
            content: " ";
            position: absolute;
            left: 0;
            top: -1px;
            bottom: -1px;
          }
        }
      }
      &.warning {
        td:first-of-type {
          &:before {
            border-left: rgba(255, 189, 100, 1) 6px solid;
          }
        }
      }
      &.critical {
        td:first-of-type {
          &:before {
            border-left: rgba(255, 66, 82, 1) 6px solid;
          }
        }
      }
    }

    .ant-table-sticky-scroll {
      background-color: var(--white);
      bottom: 40px !important;
      transition: 0.2s ease-in;
      &:active {
        opacity: 1 !important;
      }
      .ant-table-sticky-scroll-bar {
        background-color: var(--primary-color);
        &:hover {
          cursor: grab;
        }
        &:active {
          cursor: grabbing;
        }
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filter-flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .filters-wrapper {
    padding: 10px;
  }

  .filter-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    .label {
      padding-bottom: 5px;
      display: inline-block;
    }
  }

  .table-title-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 30px;
    .table-title {
      font-weight: 400 !important;
      margin-top: 0 !important;
      user-select: none !important;
      display: block;
      float: left;
      margin-right: 20px;
      margin-bottom: 0;
    }
    .table-title-left {
      display: flex;
      .ant-btn {
        margin-right: 10px;
      }
    }
  }

  .ant-spin-blur {
    overflow: unset;
  }

  .top-actions {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  .actions-bar-container {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0px;
    z-index: 99999;
    background: var(--black);
    padding: 4px;
    width: 100%;
    .action-btns {
      display: flex;
      .action-btn {
        margin-right: 10px;
        &:first-of-type {
          margin-left: 0 !important;
        }
      }
      &.smaller-actions {
        button {
          .ant-btn-icon {
            display: none;
          }
        }
      }
    }
    .right-side {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      .ant-pagination {
        color: var(--white);
        .ant-pagination-options {
          margin-left: 0;
        }
        .ant-pagination-total-text {
          margin-right: 0;
        }
        .ant-pagination-item-ellipsis {
          color: var(--white);
        }
        .anticon-double-right {
          color: var(--white);
        }
        .ant-pagination-item {
          background-color: var(--white);
          border-color: var(--primary-color);
        }
        .ant-pagination-prev,
        .ant-pagination-next {
          .ant-pagination-item-link {
            color: var(--white);
            &:hover {
              color: var(--primary-color);
            }
          }
          &.ant-pagination-disabled {
            .ant-pagination-item-link {
              color: var(--secondary-color);
            }
          }
        }
      }
      .ant-pagination-simple {
        display: flex;
        align-items: center;
        .ant-pagination-simple-pager {
          color: var(--white);
          input {
            color: var(--dark-green);
          }
        }
        .ant-pagination-item-link {
          color: var(--white);
          &:hover {
            color: var(--dark-green);
          }
        }
        .ant-pagination-disabled {
          .ant-pagination-item-link {
            color: var(--grey);
          }
        }
      }
      .divider {
        height: 80%;
        width: 1px;
        background: var(--dark-green);
        border: none;
        margin-right: 4px;
        margin-left: 3px;
        user-select: none;
      }
      .settings {
        display: flex;
        .export-btn {
          display: flex;
          align-items: center;
        }
        .settings-btn {
          color: var(--white);
          transition: 0.2s ease-in;
          margin-left: 10px;
          &:hover {
            color: var(--tertiary-color);
          }
        }
        .back-top-btn {
          padding: 6px;
          background: transparent;
          transition: 0.2s ease-in;
          border: unset;
          &:hover {
            color: var(--tertiary-color);
          }
          span {
            font-size: 20px;
          }
        }
        .screen-size-btn {
          color: var(--white);
          transition: 0.2s ease-in;
          margin-left: 5px;
          span[role="img"] {
            transition: 0.2s ease-in;
            &:hover {
              color: var(--tertiary-color) !important;
            }
          }
        }
      }
    }
  }

  .table-pagination {
    .pagination-button {
      color: var(--primary-color);
    }
    .ant-select-selection-placeholder {
      color: var(--secondary-color);
    }
    .ant-select-arrow {
      color: var(--secondary-color);
    }
  }
}

.manage-columns-modal {
  .ant-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// deprecated

// #abstract-projects-table {
//   border: 1px solid var(--grey);
//   .ant-table-thead {
//     tr {
//       th {
//         border-bottom: 1px solid var(--grey);
//       }
//     }
//   }

//   .ant-table-column-sorter-full {
//     display: flex;
//   }

//   .disabled-row {
//     color: rgba(0, 0, 0, 0.5);
//   }

//   .ant-table-tbody > .disabled-row:hover > td {
//     background: var(--white) !important;
//     cursor: not-allowed;
//     user-select: none;
//   }

//   .disabled-row .ant-checkbox-disabled .ant-checkbox-inner {
//     border-color: var(--grey) !important;
//   }

//   .disabled-row .ant-checkbox-disabled .ant-checkbox-inner:before {
//     content: "x";
//     color: var(--red);
//     font-weight: bold;
//   }
// }
