#batchEditingForm {
    .ant-form-item {
        margin-bottom: 10px;
    }
}

.batch-modal {
    display: flex;
    flex-direction: column;
    height: 550px;
  
    .ant-modal-content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  
    .ant-modal-body {
      flex: 1;
      overflow-y: auto;
      padding-right: 16px;
    }
  }