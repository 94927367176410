/* drawer */
.manage-pages-drawer .ant-drawer-header {
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 0px;
  padding: 10px 18px;
}

.manage-pages-drawer .ant-drawer-header .ant-drawer-title {
  color: var(--white);
  user-select: none;
}

.manage-pages-drawer .ant-drawer-header .ant-drawer-close {
  font-size: 16px;
  top: 3px;
  color: var(--white);
  padding: 10px;
}

.manage-pages-drawer .ant-drawer-header .ant-drawer-close:hover {
  color: #414141;
  transform: scale(1.1);
}

.manage-pages-drawer .ant-form-item {
  margin-bottom: 10px;
}
.manage-pages-drawer .ant-form-horizontal .ant-form-item-control {
  align-items: flex-end;
}

.manage-pages-drawer
  .ant-form-horizontal
  .ant-form-item-control
  .ant-form-item-control-input {
  width: 350px;
}

.manage-page .title-wrap {
  margin: 5px 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* Table */
.manage-page .manage-page-table {
  border: 1px solid var(--grey);
  border-radius: 4px;
  margin-top: 5px;
}
/* table header  */
.manage-page .manage-page-table .ant-table-thead > tr > th {
  color: var(--primary-color);
  font-weight: bold;
  border-bottom: 1px solid var(--grey);
}
.manage-page
  .manage-page-table
  .ant-table-thead
  > tr
  > th.ant-table-selection-column {
  border-right: 1px solid var(--grey);
}
.manage-page .manage-page-table .ant-table-thead > tr > .ant-table-column-sort {
  background: #e1e7eb !important;
}
/* pagination  */
.manage-page .manage-page-table .ant-pagination {
  border-top: 1px solid var(--grey);
  margin: 0;
  padding: 15px 0;
}

/* table body  */
.manage-page .manage-page-table .ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset;
}

.manage-page .manage-page-table .ant-table-tbody .ant-table-row-selected:hover {
  background: unset;
}

.manage-page .manage-page-table .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #f4f5f7;
}

.manage-page .manage-page-table .ant-table-thead > tr > .ant-table-column-sort {
  background: #e1e7eb !important;
}

.manage-page
  .manage-page-table
  .ant-table-tbody
  > tr
  > td.ant-table-selection-column {
  border-right: 1px solid var(--grey);
}

.manage-page .manage-page-table .ant-table-tbody .ant-table-row-selected td {
  background: #e4e9f0 !important;
}

.manage-page .manage-page-table .ant-skeleton-title {
  margin: 3px 0;
}

.manage-page .manage-page-table .ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 92%;
  float: left;
}

.manage-page .ant-btn {
  margin-right: 20px;
}

.manage-page .ant-input-search-button {
  margin: 0px;
  height: 30px;
}

.manage-page .ant-tag {
  margin-top: 4px;
  margin-bottom: 4px;
}

.manage-page .ant-tag,
.manage-pages-drawer .ant-select-multiple .ant-select-selection-item,
.manage-pages-drawer .ant-select-multiple .ant-select-selection-item-remove {
  background: var(--tertiary-color);
  color: var(--white);
}

.manage-pages-drawer .input-uppercase {
  text-transform: uppercase;
}

/* Drag and drop */

.manage-page .c-multi-drag-table .ant-table {
  z-index: unset;
  height: auto;
  overflow-y: unset;
}

.manage-page .c-multi-drag-table .ant-table-tbody > tr.row-item,
.manage-page
  .c-multi-drag-table
  .ant-table-tbody.is-dragging-over
  > tr.ant-table-placeholder,
.manage-page .c-multi-drag-table .ant-table-tbody > tr.row-item.row-selected,
.manage-page .c-multi-drag-table .ant-table-tbody > tr.row-item.row-dragging {
  background-position: 1% 50%;
}

.manage-page .c-multi-drag-table .ant-table-tbody > tr.row-item.row-dragging a {
  color: var(--white);
}

.manage-page .c-multi-drag-table.is-dragging table {
  border: none;
}
