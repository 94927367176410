.manage-columns-container {
  background: var(--black);
  .manage-columns-btn {
    color: var(--white) !important;
    transition: 0.2s ease-in;
    margin-left: 4px;
    &:hover {
      color: var(--tertiary-color) !important;
    }
  }
}

.manage-columns-mdl {
  .ant-modal-content {
    padding: 12px;
    .ant-modal-body {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 10px;
      .TransferList-container {
        padding-bottom: 30px;
        p {
          text-align: center;
          font-weight: bold;
          color: var(--primary-color);
        }
        .TransferListList-container {
          margin: 0 3px;
          .TransferListList-list {
            padding-top: 5px;
            height: 100%;
            border: 1px solid var(--primary-color);
            border-radius: 4px;
            @media screen and (max-height: 1100px) {
              max-height: 350px;
              overflow-y: auto;
              ul {
                margin-bottom: 0;
              }
            }
            &[data-rbd-droppable-id="right"],
            &[data-rbd-droppable-id="left"] {
              background: aliceblue;
            }
          }
          .DraggableList-list {
            min-width: 150px;
            ul {
              padding-bottom: 20px;
              overflow-x: hidden;
            }
            .css-1sfzrz5 {
              display: none;
            }
            li {
              list-style-type: none !important;
              transition: 0.1s ease-in;
              padding: 3px;
              &:hover {
                color: var(--white);
                background: var(--primary-color);
                border-radius: 4px;
                transform: scale(1.02);
              }
            }
            .DraggableListItem {
              &-dragging {
                color: var(--primary-color);
                font-weight: bold;
                max-width: 150px;
                .drag-arrow {
                  display: none;
                }
              }
              &-list-item {
                .drag-item-container {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .left-container {
                    display: flex;
                  }
                  .row-title {
                    white-space: break-spaces !important;
                    line-height: 1.2;
                  }
                  span {
                    padding: 0 5px;
                    white-space: nowrap;
                  }
                  .drag-arrow {
                    color: var(--dark-green);
                    transition: 0.2s ease-in;
                    &:hover {
                      transform: scale(1.2);
                    }
                    &__disabled {
                      color: var(--grey);
                      pointer-events: none;
                    }
                  }
                  .arrows-wrapper {
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                  }
                  &:hover {
                    .drag-arrow {
                      color: var(--white);
                    }
                  }
                }
              }
            }
          }
        }
        .left-container,
        .right-container {
          min-width: 180px;
        }
        .scrolling-container {
          min-width: 180px;
          .TransferListList-container {
            background: unset;
          }
        }
      }
      .available-columns {
        padding: 0 0 0 8px;
        position: relative;
        border-left: 1px dashed rgba(0, 128, 128, 0.3);
        .title {
          text-align: center;
          font-weight: bold;
          color: var(--primary-color);
          font-size: 13px;
        }
        .grid-container {
          display: flex;
          flex-wrap: wrap;
          max-height: 370px;
          max-width: 260px;
          flex-direction: column;
          overflow-x: scroll;
          width: calc(100% + 1px);
          min-width: 220px;
          @media (max-width: 1000px) {
            display: grid;
          }
          .column {
            margin-right: 20px;
            display: flex;
            .column-title {
              width: 100%;
              display: flex;
              max-width: 110px;
            }
          }
        }
      }
    }
  }
}
