/* Timezone Menu  */

.timezone-menu-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 170px;
  transition: 0.2s ease-in;
  padding: 0px 17px 0 10px;
  &:hover {
    background-color: var(--primary-color);
    color: var(--white);
    .ant-select {
      .ant-select-arrow {
        color: var(--white);
      }
    }
  }
  .ant-select {
    .ant-select-selector {
      color: var(--secondary-color);
      height: 100%;
      display: flex;
      align-items: center;
      border: unset;
      &:hover {
        cursor: pointer;
        color: var(--white);
      }
      input {
        cursor: pointer;
      }
    }
    .ant-select-arrow {
      color: var(--secondary-color);
      transition: 0.2s ease-in;
    }
  }

  .ant-select-open {
    .ant-select-arrow {
      color: var(--white);
    }
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: var(--white);
    height: 100%;
    display: flex;
    align-items: center;
  }
  svg {
    margin-right: -5px;
    &:hover {
      color: var(--white);
    }
  }
  .select-timezone-dropdown {
    height: 100%;
    flex-flow: column;
    align-items: center;
    .ant-select-selection-search-input {
      color: var(--white) !important;
    }
    .ant-select-selection-search-input {
      color: var(--white) !important;
    }
  }

  &.timezone-menu-active {
    background-color: var(--primary-color);
    color: var(--white);
  }
  &.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: var(--white);
  }
  &.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 100%;
  }
}
