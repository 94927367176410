.extensionsTable {
  .filters {
    .date-filter-wrap {
      .trigger-filter,
      .list-filter-wrap {
        width: 100%;
        justify-content: flex-start;
      }
    }
  }
}
