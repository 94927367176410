/* Collapse header  */
.invoicing-project-details-body
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  font-weight: bold;
  background-color: #f4f5f7;
  color: var(--primary-color);
  border-radius: 4px 0 0 0;
  user-select: none;
  padding: 7px 35px;
}

.invoicing-project-details-body .ant-table-column-sorters {
  padding: 0;
}
.invoicing-project-details-body .ant-collapse {
  border: 1px solid var(--grey);
}
.invoicing-project-details-body .ant-collapse-content {
  border-top: 1px solid var(--grey);
}
.invoicing-project-details-body .ant-collapse > .ant-collapse-item {
  border-bottom: unset;
}

/* General Information */
.invoicing-project-details-body .general-information .ant-descriptions-header {
  margin-bottom: 0;
  padding: 0 3px;
  background: #f4f5f7;
}
.invoicing-project-details-body
  .general-information
  .ant-descriptions-header
  .ant-descriptions-title {
  font-size: 14px;
  color: var(--primary-color);
}
.invoicing-project-details-body
  .general-information
  .ant-descriptions-bordered.ant-descriptions-small
  .ant-descriptions-item-label {
  padding: 2px !important;
  max-width: 90px;
  font-weight: bold;
  text-transform: uppercase;
}

.invoicing-project-details-body
  .general-information
  .ant-descriptions-bordered.ant-descriptions-small
  .ant-descriptions-item-content {
  padding: 2px !important;
}
.invoicing-project-details-body
  .general-information
  .ant-descriptions-bordered.ant-descriptions-small
  .ant-descriptions-item-content
  svg:hover {
  cursor: pointer;
}

.invoicing-project-details-body
  .general-information
  .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 6px 16px;
}

.invoicing-project-details-body
  .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0;
}

/* Tables header  */
.invoicing-project-details-body
  .ant-table-thead
  > tr:first-child
  th:first-child {
  width: 2%;
}

.invoicing-project-details-body .ant-table-thead > tr > th {
  color: var(--primary-color);
  font-weight: bold;
  border-bottom: 1px solid var(--grey);
  padding: 10px 8px !important;
  text-align: center;
  user-select: none;
}

.invoicing-project-details-body
  .ant-table-thead
  > tr
  > th.ant-table-selection-column {
  border-right: 1px solid var(--grey);
  padding: 10px 5px !important;
}

.invoicing-project-details-body .ant-table-thead > tr > .ant-table-column-sort {
  background: #e1e7eb !important;
}

/* Invoicing table  */
/* Table Header */
.invoicing-table-wrap .table-header {
  padding: 15px 16px;
}
.invoicing-project-details-body .invoicing-table-wrap .ant-collapse-content {
  border-top: unset;
}

/* Tables body  */
.invoicing-table-wrap .invoicing-table {
  border-top: 1px solid var(--grey);
}
.invoicing-project-details-body .ant-table-tbody > tr > td {
  padding: 10px !important;
  text-align: center;
}

.invoicing-project-details-body
  .ant-table-tbody
  > tr
  > td.ant-table-selection-column {
  border-right: 1px solid var(--grey);
  padding: 10px 5px !important;
}

.invoicing-project-details-body .collapsable-table .ant-collapse-content {
  overflow: unset;
}

.invoicing-project-details-body .ant-table-tbody .ant-table-row-selected td {
  background: var(--white) !important;
}

/* Disabled rows */
.invoicing-table-wrap .invoicing-table .disabled-row {
  color: rgba(0, 0, 0, 0.5);
}
.ant-table-tbody > .disabled-row:hover > td {
  background: var(--white) !important;
  cursor: not-allowed;
  user-select: none;
}
.invoicing-table-wrap
  .invoicing-table
  .disabled-row
  .ant-checkbox-disabled
  .ant-checkbox-inner {
  border-color: var(--grey) !important;
}
.invoicing-table-wrap
  .invoicing-table
  .disabled-row
  .ant-checkbox-disabled
  .ant-checkbox-inner:before {
  content: "x";
  color: var(--red);
  font-weight: bold;
}

.invoicing-project-details-body .ant-table-tbody .ant-table-measure-row td {
  padding: 0 !important;
}

/* Table footer */
.invoicing-table-wrap .ant-table-footer {
  border-top: 1px solid var(--grey);
  padding: 5px 20px;
}
/* Table footer */
.invoicing-table-wrap .ant-table-footer .question-mark {
  color: var(--tertiary-color);
}
.invoicing-table-wrap .ant-table-footer .question-mark:hover {
  cursor: help;
}

.danger-modal .ant-btn-default {
  color: white !important;
}
.danger-modal .ant-btn-dangerous {
  background: var(--red) !important;
}

/* Actions Section  */
.invoicing-details-actions .ant-space-item {
  margin-top: 15px;
}
.invoicing-details-actions .ant-space-gap-row-large {
  row-gap: 4px;
}
.invoicing-content-wrap .action-btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.invoicing-content-wrap .action-btn:hover {
  color: #f6f6f6;
}
.invoicing-content-wrap .action-green-btn {
  background-color: var(--tertiary-color);
  border-color: var(--tertiary-color);
}
.invoicing-content-wrap .action-green-btn:hover {
  background-color: var(--dark-green);
  border-color: var(--dark-green);
}

.invoicing-content-wrap .reload-btn:hover {
  color: var(--tertiary-color);
}
.invoicing-content-wrap .reload-btn .anticon-reload {
  transition: 0.2s ease-in;
}
.invoicing-content-wrap .reload-btn:hover .anticon-reload {
  transform: rotate(360deg);
}

/* scroll up button  */
.invoicing-project-details-body .scroll-up {
  font-size: 24px;
  color: var(--white);
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: 5px;
  transition: 0.2s ease-in;
}
.invoicing-project-details-body .scroll-up:hover {
  background: var(--primary-color);
}
