.notifications {
  position: relative;
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 10px;
  h2 {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .notifications-actions {
    button {
      margin: 0;
    }
  }

  .ant-timeline.ant-timeline-alternate
    .ant-timeline-item-right
    .ant-timeline-item-content {
    width: calc(50% - 18px);
  }
  .ant-timeline.ant-timeline-alternate
    .ant-timeline-item-left
    .ant-timeline-item-content {
    width: calc(50% - 20px);
  }
  .notification:before {
    border-top: 0.5rem solid transparent;
    display: block;
    position: absolute;
    top: 7px;
    content: " ";
    left: -5px;
    width: 10px;
    height: 10px;
    background: white;
    transform: rotate(45deg);
    border-left: #ccc solid 1px;
    border-bottom: #ccc solid 1px;
  }
  .ant-timeline-item-right .notification:before {
    left: unset;
    right: -5px;
    border: none;
    border-right: #ccc solid 1px;
    border-top: #ccc solid 1px;
  }
  span.notification-timestamp {
    text-decoration: none;
    position: absolute;
    top: 2px;
    font-weight: bolder;
    .notification-fromnow {
      font-weight: normal;
      font-style: italic;
    }
  }
  .ant-timeline-item-right {
    .notification-actions {
      left: 10px;
    }
    span.notification-timestamp {
      .notification-fromnow {
        text-align: left;
      }
    }
    span.notification-timestamp {
      right: -180px;
    }
    .notification-arrow {
      right: -7px;
      border-left: 0.5rem solid #fff;
    }
  }
  .ant-timeline-item-left {
    .notification-actions {
      right: -10px;
    }
    span.notification-timestamp {
      .notification-fromnow {
        text-align: right;
      }
    }
    span.notification-timestamp {
      left: -180px;
    }
    .notification-arrow {
      left: -7px;
      border-right: 0.5rem solid #fff;
    }
  }
  .notification-container {
    .notification {
      background: #fefefe;
      border-radius: 5px;
      border: 1px #cccccc solid;
      padding: 10px;
      span {
        display: block;
      }
      .notification-title {
        font-weight: bolder;
        font-size: 1.1em;
        padding: 0 0 5px 0;
      }
      .notification-link {
        padding: 0;
        button {
          margin: 10px 0 0 0;
          transition: 0.2s ease-in;
          &:hover {
            background: var(--tertiary-color);
          }
        }
      }
    }
  }
}
